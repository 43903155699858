
/**
 * = Animations
 */

 @for $size from 1 to 10 {
    .animate-up-#{$size},
    .animate-right-#{$size},
    .animate-down-#{$size},
    .animate-left-#{$size},
    .scale-up-#{$size},
    .scale-down-#{$size} {
        transition: $transition-base;
    }

    .scale-up-#{$size} {
        &:hover {
            transform: scale($size / 1.8);
        }
    }

    .scale-up-hover-#{$size} {
        &:hover {
            & .scale {
                transform: scale($size / 1.8);
            }
        }
    }

    .scale-down-#{$size} {
        &:hover {
            transform: scale($size / 2.5);
        }
    }

    .animate-up-#{$size} {
        &:hover,
        .animate-hover:hover & {
            @include translate(0, -#{$size}px);
        }
    }
    .animate-right-#{$size} {
        &:hover,
        .animate-hover:hover & {
            @include translate(#{$size}px, 0);
        }
    }
    .animate-down-#{$size} {
        &:hover,
        .animate-hover:hover & {
            @include translate(0, #{$size}px);
        }
    }
    .animate-left-#{$size} {
        &:hover,
        .animate-hover:hover & {
            @include translate(-#{$size}px, 0);
        }
    }
}

// Keyframes
@keyframes show-navbar-collapse {
    0% {
        opacity: 0;
        transform: scale(.95);
        transform-origin: 100% 0;
    }

    100% {
        opacity: 1;
        transform: scale(1);
    } 
}

@keyframes hide-navbar-collapse {
    from {
        opacity: 1;
        transform: scale(1);
        transform-origin: 100% 0;
    }

    to {
        opacity: 0;
        transform: scale(.95);
    } 
}

@keyframes show-navbar-dropdown {
    0% {
        opacity: 0;
        transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
        transition: visibility 0.45s, opacity 0.45s, transform 0.45s;
    }

    100% {
        transform: translate(0, 0);
        opacity: 1;
    } 
}

@keyframes hide-navbar-dropdown {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: translate(0, 10px);
    } 
}

@keyframes show-dropdown {
    0% {
        opacity: 0;
        transform-origin: perspective(200px) rotateX(-2deg);
        transition: visibility 0.45s, opacity .5s;
    }

    100% {
        opacity: 1;
    } 
}


@keyframes hide-dropdown {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: translate(0, 10px);
    } 
}
