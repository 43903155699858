/**
 * = Images
 */

.image-lg {
    height: 12rem;
}

.image-md{
    height: 5rem;
}

.image-sm {
    height: 3rem;
}

.image-xs {
    height: 1.875rem;
}

.img-thumbnail {
    border-width: $border-width-md;
    box-shadow: none;
}

.full-image{
    height:100%;
}

.gallery-feed{
    img{
        width: 20%;
        margin-right: .5rem;
        margin-bottom: .5rem;
        float: left;
    }
}
@include media-breakpoint-up(xl){
    .effect-img-2 {
        position: absolute;
        right: 5rem;
        top: 19%;
        z-index: 2;
        margin: 0;
    }

    .effect-img-1, .effect-img-2 {
        margin: 0 0 3rem;
        width: 350px;
        height: auto;
    }
}

@include media-breakpoint-down(lg){
    .effect-img-2{
        right:.425rem;
        top:0;
    }
}
